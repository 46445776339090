var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"team-members-table",attrs:{"headers":_vm.headers,"items":_vm.members,"disable-pagination":"","hide-default-footer":"","disable-sort":"","fixed-header":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('material-team-members-form-modal')],1),_c('v-divider')]},proxy:true},{key:"header.actions",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.permissions",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPermissionsString(item.permissions))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right mr-n1"},[_c('material-team-members-form-modal',{attrs:{"team-member":item}}),_c('material-team-members-delete-button',{attrs:{"team-member":item}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }