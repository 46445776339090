<template>
  <v-data-table
    :headers="headers"
    :items="members"
    disable-pagination
    hide-default-footer
    class="team-members-table"
    disable-sort
    fixed-header
    data-cy="team-members-table"
  >
    <template #top>
      <v-toolbar
        flat
        color="white"
      >
        <material-team-members-form-modal />
      </v-toolbar>
      <v-divider />
    </template>
    <template #header.actions="{ header }">
      <div class="text-right">
        {{ header.text }}
      </div>
    </template>
    <template
      #item.name="{ item }"
      class="text-center"
      :data-cy="`team-members-id-${item.id}`"
    >
      {{ item.name }}
    </template>
    <template
      #item.permissions="{ item }"
      class="text-center"
    >
      {{ getPermissionsString(item.permissions) }}
    </template>
    <template #item.actions="{ item }">
      <div class="text-right mr-n1">
        <material-team-members-form-modal :team-member="item" />
        <material-team-members-delete-button :team-member="item" />
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { PERSON_ICON } from '../../../constants/app'
export default {
  props: {
    headers: {
      type: Array,
      required: true
    },
    members: {
      type: Array,
      required: true
    }
  },
  data: () => {
    return {
      personIcon: PERSON_ICON
    }
  },
  methods: {
    getPermissionsString (permissions) {
      return permissions.map(permission => this.$i18n.t(`team.${this.camelize(permission)}`)).join(', ')
    },
    camelize (string) {
      return this.$options.filters.camelize(string)
    }
  }
}
</script>

<style lang="scss">
.team-memebrs-table {
  display: table;

  tr > td:last-child {
    text-align: right;
  }
}
</style>
